import App from './App';
import { routing as reportsRouting } from './panels/reports';
import { routing as documentationRouting } from './panels/documentation';
import { routing as ticketPackagesRouting } from './panels/ticket-packages';
import { routing as salesRouting } from './panels/sales';
import { routing as playoffGamesRouting } from './panels/playoff-games';
import { routing as problematicListingRouting } from './panels/problematic-listing-report';
import { routing as clientsRouting } from './panels/clients';
import { routing as seasonsRouting } from './panels/seasons';
import { routing as authRouting } from './panels/auth';
import { routing as singleEventRouting } from './panels/single-event';
import { routing as pricingRouting } from './panels/pricing';
import { routing as awaitingBroadcastRouting } from './panels/awaiting-broadcast-tickets';
import { routing as paymentsRouting } from './panels/payments';
import { routing as saleGuaranteeRouting } from './panels/sale-guarantee';
import h from './lib/react-hyperscript';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: h(App),
    children: [
      {
        path: 'awaiting-broadcast-tickets',
        ...awaitingBroadcastRouting,
      },
      {
        path: 'reports',
        ...reportsRouting,
      },
      {
        path: 'documentation',
        ...documentationRouting,
      },
      {
        path: 'ticket-packages',
        ...ticketPackagesRouting,
      },
      {
        path: 'pricing',
        ...pricingRouting,
      },
      {
        path: 'sales',
        ...salesRouting,
      },
      {
        path: 'playoff-rounds',
        ...playoffGamesRouting,
      },
      {
        path: 'problematic-listing-report',
        ...problematicListingRouting,
      },
      {
        path: 'clients',
        ...clientsRouting,
      },
      {
        path: 'season-calendars',
        ...seasonsRouting,
      },
      {
        path: 'login',
        ...authRouting,
      },
      {
        path: 'single-event',
        ...singleEventRouting,
      },
      {
        path: 'payments',
        ...paymentsRouting,
      },
      {
        path: 'price-guarantee',
        ...saleGuaranteeRouting,
      },
    ],
  },
]);

export { router };
