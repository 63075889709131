import h from '../../lib/react-hyperscript';
import SaleGuaranteePanel from './component/SaleGuaranteePanel';
import { SaleGuaranteeListPanel } from './component/SaleGuaranteeListPanel';

const routing = {
  element: h(SaleGuaranteePanel),
  children: [
    {
      path: '',
      element: h(SaleGuaranteeListPanel),
    },
  ],
};

export { routing };
