export enum LeagueId {
  NFL = 'NFL',
  NBA = 'NBA',
  NHL = 'NHL',
  MLB = 'MLB',
  MLS = 'MLS',
  NCAAB = 'NCAAB',
  NCAAF = 'NCAAF',
}

export type LeagueStatus = {
  league: LeagueId;
  seq: number;
  in_selling_season: boolean;
  in_playoff_season: boolean;
  selling_season: number;
  signup_season: number;
  end_of_regular_season: string | null;
};

export type SeatDetails = {
  section: string;
  row: string;
  first_seat: string;
  last_seat: string;
};

export type TicketPackage = {
  season: number;
  team_id: number;
  league: LeagueId;
  sth_status: 'STH' | 'PSTH' | 'NSTH';
  num_games?: number;
  is_playoff: boolean;
  note?: string;
  stock_type?: string;
} & SeatDetails;

export type SingleEventInventory = {
  event_id: number;
  stock_type?: string;
  commission: string;
  third_party_cost: string;
  team_id?: number;
  req_id?: number;
} & SeatDetails;

export enum QualifyStatus {
  PENDING = 'pending',
  QUALIFIED = 'qualified',
  NOT_QUALIFIED = 'not_qualified',
  INCORRECT_INFO = 'incorrect_info',
}

export enum SupportStatus {
  PENDING = 'pending',
  SUPPORTED = 'supported',
  NOT_SUPPORTED = 'not_supported',
}

export enum ConversionStage {
  SOLD = 'sold',
  LISTED = 'listed',
  DELISTED_ALL = 'delisted_all',
  LOGGED_ON = 'logged_on',
  ACTIVATED = 'activated',
  PENDING = 'pending',
  SUPPORTED = 'supported',
  NOT_SUPPORTED = 'not_supported',
  INCORRECT_INFO = 'incorrect_info',
}

export enum AllOption {
  ALL = 'All',
}
export type QualifyOption = QualifyStatus | AllOption;

export enum CredsValidationStatus {
  PENDING = 'pending',
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum CredsStatus {
  PENDING = 'pending',
  INVALIDATED = 'invalidated',
  VALIDATED = 'validated',
  NOT_SUBMITTED = 'not_submitted',
}

export type CredsInfo = {
  creds_id: number;
  creds_status: CredsStatus;
  creds_status_changed: Date;
  creds_username: string | null;
  creds_password_token: string | null;
};

export type ClientTicketPackage = {
  id: number;
  tps_key: string;
  created: Date;
  client_id: number;
  qualify_status: QualifyStatus;
  support_status: SupportStatus;
  did_pay_support_fee: boolean;
  in_selling_season: boolean;
  is_shown: boolean;
  selling_season: 2021 | 2022 | 2023 | 2024;
  tp_season: number;
  ac_link: string;
  ac_deal_id?: string;
  note?: string;
  is_marketplace_verified: boolean;
  has_notes: boolean;
  is_awaiting_tickets: boolean;
  submission_note?: string;
} & TicketPackage;

export type ClientTicketPackageEx = ClientTicketPackage & {
  client_name: string;
  team_name: string;
  inventory_count: number;
  in_selling_season: boolean;
  skybox_link: string;
  commission?: string;
  third_party_cost?: string;
  commission_tier?: string;
  adjustment?: string;
  commission_experiment: 'control' | 'experiment' | 'n/a';
  is_archived: boolean;
  archive_message?: string;
} & CredsInfo;

export interface Team {
  id: number;
  name: string;
  name_short: string;
  short: string; // deprecated
  abbrev: string;
  league: LeagueId;
  city: string;
  state: string;
  default_stock_type: string;
  ticketmaster_system_id: number | null;
}

export type Season = {
  id: number;
  league: LeagueId;
  start_year: number;
  season_cutoff: string;
  start_confirmation_date: string;
  start_selling_season_date?: string;
  end_regular_season_date: string;
  end_skybox_date: string;
  in_selling_season: boolean;
  in_playoff_season: boolean;
  is_current_season?: boolean;
  in_active_playoff_season?: boolean;
};

export type ClientSummary = {
  id: number;
  first_name: string;
  last_name: string;
  registered: Date;
  email: string;
  phone?: string;
  client_tag: string;
  ac_contact_id: string;
  links: { label: string; url: string }[];
  teams: Team[];
  commission_experiment: 'control' | 'experiment';
  note?: string;
  has_notes: boolean;
};

export interface InventoryItem {
  id: number;
  created: Date;
  updated: Date;
  tps_key: string | null;
  client_id: number;
  owner_is_client: boolean;
  league?: LeagueId;
  event_date_local: string;
  event_date: Date;
  event_date_tz: Date;
  event_id: number;
  event_name: string;
  home_team_id?: number;
  home_team?: string;
  home_team_abbrev?: string;
  away_team_id?: number;
  away_team?: string;
  away_team_abbrev?: string;
  section: string;
  row: string;
  seats: string[];
  is_broadcasted: boolean;
  is_broadcastable: boolean | null;
  is_awaiting_broadcast: boolean;
  broadcasted?: Date;
  is_marketplace_broadcasted?: boolean;
  tp_id?: number;
  // For inventory that has been priced
  is_priced: boolean;
  alert_price?: string;
  list_price?: string;
  rounded_list_price?: number;
  starting_list_price?: string;
  tiq_commission?: string;
  third_party_cost?: string;
  target_payout?: string;
  is_free_listing?: boolean;

  // For inventory that has been listed
  special_instrux?: string;
  listed?: Date;
  delisted?: Date;

  // For inventory that has been sold
  is_sold: boolean;
  adjusted_sale_price?: string;
  sale_price?: string;
  // Same as sale_price, but rounded up to nearest dollar
  client_facing_sale_price?: number;
  target_payment_date?: string;
  actual_payout?: string;
  actual_payout_float?: number;
  invoice_external_ref?: string;
  invoice_date?: Date;
  season?: string;

  is_sale_guarantee: boolean;
  guaranteed_price: string;

  // playoff record
  is_price_shown?: boolean;

  // For inventory that has been sold and paid
  actual_payment_date?: Date;

  playoff_round_id?: number;
  // Deprecated
  payment_submitted_date?: Date;

  skybox: object;

  is_model_priced: boolean;
  tm_event_id?: number;
}

export type ClientEvent = {
  id: number;
  date: string;
  name: string;
  // Can't depend on fields that are not verified by skybox import
  venue?: {
    id?: number;
    name?: string;
    city?: string;
    state?: string;
  };
  inv?: InventoryItem;
  is_usual_venue?: boolean;
  vividSeatsEventUrl?: string;
  stubhubEventUrl?: string;
  performer?: { id: number; name: string };
};

export type Client = {
  id: number;
  client_tag: string;
  ac_contact_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  last_seen: string;
};

export type ClientEx = Client & {
  is_client: boolean;
  first_three_sales: number[];
};

export type OccUser = {
  id: number;
  name: string;
  email: string;
  color: string;
};

export type BaseData = {
  teams: Team[];
  leagues: LeagueStatus[];
  seasons: Season[];
  clients: ClientEx[];
  playoff_rounds: PlayoffRound[];
  commissions: Commission[];
  occ_users: OccUser[];
  clientsHavingNotes: { id: number }[];
  packagesHavingNotes: { id: number }[];
};

export type PlayoffRound = {
  id: number;
  name: string;
  league: LeagueId;
  season: number;
  round_order: number;
  league_name: string;
  skybox_match?: string;
  window_start?: string;
  window_end?: string;
};

export enum EventStatus {
  pending = 'pending',
  listable = 'listable',
  not_happening = 'not_happening',
  completed = 'completed',
}

export type PlayoffGame = {
  id: number;
  home_team_id: number;
  playoff_round_id: number;
  status: EventStatus;
};

export type PlayoffGameEx = PlayoffGame & {
  league: LeagueId;
};

export type PlayoffTicketPackageInv = {
  playoff_round_name: string;
  event_status: EventStatus;
  is_priced: boolean;
  is_date_tbd: boolean;
  is_time_tbd: boolean;
};

export type PlayoffTicketPackage = {
  is_archived: boolean;
  is_client: boolean;
} & SeatDetails;

export enum Marketplace {
  TICKET_MASTER = 'ticket_master',
}

export type MarketListing = {
  id: number;
  market_place: Marketplace;
  event_id: number;
  event_name: string;
  event_date: Date;
  home_team_id?: number;
  away_team_id?: number;
  section: string;
  row: string;
  first_seat: string;
  last_seat: string;
  price: number;
};

export type PricingFactor = {
  id: number;
  order: number;
  category: string;
  name: string;
  is_positive: boolean;
  description: string;
};

export type SaleConfirmationEmail = {
  id: number;
  client_email: string;
  created: string;
  updated: string;
  inventory_id: number;
  email_vars: { [key: string]: string | string[] | number | undefined };
  status: 'ERROR' | 'COMPLETED' | 'IN_PROGRESS' | 'NOT_SENT';
  comp_sale?: { [key: string]: string | number | undefined };
  bcc_email?: string;
  pricing_factors?: { id: number; value?: string }[];
  is_sale_guarantee?: boolean;
};

export type SalesReports = {
  inv_item: InventoryItem & { subsidy: number };
  inv_price_detail: { [key: string]: string | number | undefined };
  client_sales: (InventoryItem & { subsidy: number })[];
  seat_chart: string;
  comps: MarketListing[];
  total_listings: number;
  sale_confirmation?: SaleConfirmationEmail;
  tm_event_link?: string;
  sale_statistics: SaleStatistic;
  pricing_factors: PricingFactor[];
};

export type SaleStatistic = {
  sales_amount_all_time: number;
  sales_amount_cur_season: number;
  sales_volume_all_time: number;
  sales_volume_cur_season: number;
  median_sale_price: number;
};

export type Sale = InventoryItem;

export type AttributeItem = {
  id: string;
  order: number;
  auto_suggested: boolean;
  selected: boolean;
  disabled: boolean;
  title: string;
  category: string;
  options: {
    id: number;
    label: string;
    type: 'cons' | 'pros';
  }[];
  value?: number;
  input: {
    value: string | null;
    type: string;
    editable: boolean;
    placeholder: string;
  };
  additional_info?: string;
};

export type ReportsPage = {
  version: number;
  created: Date;
  source: string;
};

export type TaxStatus = 'submitted' | 'approved' | 'rejected';

export type TaxStatusDetails = {
  client_id: number;
  updated: string;
  status: TaxStatus;
  is_nonresident_alien: boolean;
  attempts: number;
};

export type Commission = {
  start_date: string;
  end_date: string | null;
  fee_label: number;
  third_party_cost: number;
  tiq_commission: number;
  tiq_exp_commission: number;
  commission_tier: string;
  note: string | null;
  is_active: boolean;
};

export type ActionLog = {
  id: number;
  user_id: number;
  user_fullname?: string;
  email?: string;
  ref_id: number;
  category?: string;
  created: Date;
  ref_type: string;
  description: string;
  detail?: string;
  status: string;
};
