import {
  ClientDrillDown,
  format,
  PageDataGrid,
} from '../../../common/components';
import { useContext, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import { stringDateTime } from '../../../common/util';
import * as api from '../../../common/api';
import DataGridNoteColumnV2 from '../../../common/components/DataGridNoteColumnV2';
import { AppContext } from '../../../common/store/context';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { SmartToyOutlined as SmartToyIcon } from '@mui/icons-material';

const RenderAmpCell = (params: GridRenderCellParams<any>) => {
  const { setNotification } = useContext(AppContext);
  const [showConfirmation, setConfirmation] = useState(false);
  const [is_loading, setLoading] = useState(false);
  const [model_price, setModelPrice] = useState(params.row.list_price);
  const handleOnClick = () => {
    setLoading(true);
    api
      .setAmpPricingForSaleGuarantee(params.row.id, model_price)
      .then(() => {
        setLoading(false);
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `Successfully register AMP pricing for inventory: id=${params.row.id}`,
        });
      })
      .catch(() => {
        setLoading(false);
        setNotification({
          open: true,
          severity: 'error' as AlertColor,
          msg: `Failed to register AMP pricing for inventory: id=${params.row.id}`,
        });
      });
  };

  return h(Stack, {}, [
    ...(!params.row?.is_sold
      ? [
          is_loading
            ? h(CircularProgress, { size: 20, thickness: 4 })
            : h(
                IconButton,
                {
                  'aria-label': 'amp',
                  size: 'small',
                  color: 'primary',
                  onClick: () => {
                    setConfirmation(true);
                  },
                },
                [h(SmartToyIcon)],
              ),

          h(
            Dialog,
            { open: showConfirmation, onClose: () => setConfirmation(false) },
            [
              h(
                Box,
                {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                  },
                },
                [
                  h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
                    'Pricing with AMP',
                  ]),

                  h(
                    Stack,
                    {
                      direction: 'column',
                      justifyContent: 'center',
                      gap: 2,
                    },
                    [
                      h(
                        Stack,
                        {
                          direction: 'row',
                          alignItems: 'center',
                          gap: 1,
                        },
                        [
                          h(
                            Typography,
                            { fontSize: '16px' },
                            `${params.row?.event_name},`,
                          ),
                          h(
                            Typography,
                            { fontSize: '16px' },
                            `${format.datetime({
                              value: params.row?.event_date_local,
                            })}`,
                          ),
                        ],
                      ),
                      h(
                        Stack,
                        {
                          direction: 'row',
                          alignItems: 'center',
                          gap: 1,
                        },
                        [
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `(${params.row?.league}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `${params.row?.home_team_abbrev}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `S${params.row?.section}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px', fontWeight: 'bold' },
                            '|',
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `R${params.row?.row}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px', fontWeight: 'bold' },
                            '|',
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `S${params.row?.seats})`,
                          ),
                        ],
                      ),
                      h(
                        Stack,
                        {
                          direction: 'row',
                          alignItems: 'center',
                          gap: 1,
                        },
                        [
                          h(
                            Typography,
                            { fontSize: '16px' },
                            `Current list price: `,
                          ),
                          h(
                            Typography,
                            { fontSize: '16px', fontWeight: 'bold' },
                            `$ ${params.row?.list_price}`,
                          ),
                        ],
                      ),

                      h(TextField, {
                        required: true,
                        id: 'model_price',
                        label: 'Model Price',
                        name: 'model_price',
                        size: 'small',
                        value: model_price,
                        inputProps: {
                          type: 'number',
                          pattern: '[0-9]*',
                        },
                        InputProps: {
                          startAdornment: h(
                            InputAdornment,
                            { position: 'start' },
                            '$',
                          ),
                        },
                        onChange: (e) => {
                          setModelPrice(e.target.value);
                        },
                      }),

                      h(
                        Stack,
                        {
                          direction: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                        [
                          h(
                            Button,
                            {
                              variant: 'contained',
                              sx: { marginX: 2 },
                              color: 'error',
                              onClick: () => setConfirmation(false),
                            },
                            ['No'],
                          ),
                          h(
                            Button,
                            {
                              variant: 'contained',
                              sx: { marginX: 2 },
                              color: 'success',
                              onClick: () => {
                                handleOnClick();
                                setConfirmation(false);
                              },
                            },
                            ['Yes'],
                          ),
                        ],
                      ),
                    ],
                  ),
                ],
              ),
            ],
          ),
        ]
      : []),
  ]);
};

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Event Details',
    children: [
      { field: 'created' },
      { field: 'client_name' },
      { field: 'client_id' },
      { field: 'event_id' },
      { field: 'event_name' },
      { field: 'event_date_local' },
      { field: 'days_to_event' },
      { field: 'is_client' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'stock_type' },
    ],
  },
  {
    groupId: 'Pricing',
    children: [
      { field: 'listed' },
      { field: 'listed_days_to_event' },
      { field: 'guaranteed_price' },
      { field: 'list_price' },
      { field: 'tiq_commission' },
      { field: 'third_party_cost' },
      { field: 'is_model_priced' },
    ],
  },
  {
    groupId: 'Contact Info',
    children: [
      { field: 'email' },
      { field: 'phone' },
      { field: 'ac_contact_id' },
    ],
  },
  { groupId: 'Note', children: [{ field: 'note' }] },
  {
    groupId: 'Actions',
    children: [{ field: 'AMP_PRICING' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    width: 120,
    resizable: false,
    hideable: false,
    pinnable: false,
    renderCell: (params) =>
      DataGridEntryButton(
        params,
        `https://skybox.vividseats.com/inventory?ef.keywords="${params.row.event_name}"&inf.section=${params.row.section}&inf.row=${params.row.row}`,
        params.row.is_playoff ? { color: 'warning.main' } : {},
      ),
  },
  stringDateTime({
    field: 'created',
    minWidth: 175,
  }),
  {
    field: 'client_name',
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  {
    field: 'is_client',
    width: 75,
    type: 'boolean',
  },
  { field: 'event_id', width: 90 },
  { field: 'event_name', minWidth: 400 },
  stringDateTime({
    field: 'event_date_local',
    minWidth: 175,
  }),
  { field: 'days_to_event', type: 'number', align: 'right', width: 90 },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => {
      row.seats.sort();
      // get the first and last seat
      const first_seat = row.seats[0];
      const last_seat = row.seats[row.seats.length - 1];
      return first_seat + (last_seat === first_seat ? `-${last_seat}` : '');
    },
    minWidth: 50,
    maxWidth: 100,
  },
  {
    field: 'stock_type',
    align: 'right',
    minWidth: 80,
    valueGetter: ({ row }) => row.skybox?.stockType,
  },

  { field: 'listed', type: 'dateTime', valueFormatter: format.datetime },
  {
    field: 'listed_days_to_event',
    type: 'number',
    valueGetter: ({ row }) =>
      Math.round(
        Math.abs(
          (new Date(row.event_date_tz).getTime() -
            new Date(row.listed).getTime()) /
            (1000 * 60 * 60 * 24),
        ),
      ),
  },
  { field: 'guaranteed_price', minWidth: 80, align: 'right' },
  { field: 'list_price', minWidth: 80, align: 'right' },
  {
    field: 'tiq_commission',
    headerName: 'commission',
    align: 'right',
    minWidth: 70,
  },
  {
    field: 'third_party_cost',
    headerName: 'third_party_cost',
    align: 'right',
    minWidth: 70,
  },
  {
    field: 'is_model_priced',
    width: 75,
    type: 'boolean',
  },
];
const contact_info_columns: GridColDef[] = [
  { field: 'email', minWidth: 230, align: 'left' },
  { field: 'phone', minWidth: 140, align: 'left' },
  {
    field: 'ac_contact_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
];

export function SaleGuaranteeDataGrid({
  rows,
  onDataUpdated,
}: {
  rows: any[];
  onDataUpdated: () => void;
}) {
  const [data, setData] = useState(rows);
  useEffect(() => {
    setData(rows);
  }, [rows]);

  const onSaveSucceed = (row: any, field: string, value: any) => {
    const new_data = [...data];
    const target_idx = data.findIndex((item) => item.id === row.id);
    new_data[target_idx][field] = value;
    setData(new_data);
    onDataUpdated();
  };

  return PageDataGrid({
    id: 'sale-guarantee',
    rows: data,
    column_specs: [
      ...column_specs,
      ...contact_info_columns,
      DataGridNoteColumnV2({
        field: 'note',
        valueGetter: ({ row }) => row.note,
        headerName: 'inventory',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.client_name}'s ticket for ${row.event_name}, ${format.datetime(
            { value: row.event_date_local },
          )} (sec ${row.section}, row ${row.row}, s [${row.seats}])`,
        hasNoteBody: (row) => !!row.has_notes,
        api_key_field: 'id',
      }),

      {
        field: 'AMP_PRICING',
        headerAlign: 'center',
        align: 'center',
        headerName: 'AMP',
        minWidth: 100,
        sortable: false,
        hideable: false,
        filterable: false,
        resizable: false,
        pinnable: false,
        disableColumnMenu: true,
        renderCell: RenderAmpCell,
      },
    ],
    column_groups: column_groups,
    filter_specs: [],
    add_button: false,
  });
}
