import * as _ from 'ramda';
import { useBaseData } from '../../../common/base_data';
import { Sale } from '../../../common/types';

import {
  QuickFilterSpec,
  PageDataGrid,
  format,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import { durationDays } from '../../../common/helper';
import { stringDateTime } from '../../../common/util';

const column_groups = [
  { groupId: 'Entry', children: [{ field: 'id' }] },
  {
    groupId: 'Client',
    children: [{ field: 'client_id' }, { field: 'client_name' }],
  },
  {
    groupId: 'Tickets',
    children: [
      { field: 'invoice_external_ref' },
      { field: 'league' },
      { field: 'home_team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'away_team' },
      { field: 'event_date_local' },
      { field: 'days_to_event' },
    ],
  },
  {
    groupId: 'Sale',
    children: [
      { field: 'invoice_date', valueFormatter: format.datetime },
      { field: 'num_seats' },
      { field: 'sale_price_per' },
      { field: 'total_sale_price' },
      { field: 'days_listed' },
      { field: 'is_sale_guarantee' },
    ],
  },
  {
    groupId: 'Status',
    children: [
      { field: 'Sale 1-3' },
      { field: 'confirmed_at' },
      { field: 'is_confirmed' },
    ],
  },
];

const column_specs: GridColDef[] = [
  { field: 'client_id', width: 75, align: 'right' },
  { field: 'client_name' },
  {
    field: 'id',
    width: 120,
    align: 'center',
    renderCell: (params) => DataGridEntryButton(params, `/sales/${params.id}`),
  },
  {
    field: 'invoice_external_ref',
    align: 'right',
    headerName: 'Ext ID',
  },
  { field: 'league' },
  { field: 'home_team', headerName: 'team' },
  { field: 'section' },
  { field: 'row' },
  { field: 'seats' },
  { field: 'away_team', headerName: 'opponent' },
  stringDateTime({
    field: 'event_date_local',
    minWidth: 150,
    cellClassName: ({ value }) =>
      new Date() > new Date(value) ? 'secondary' : '',
  }),
  {
    field: 'days_to_event',
    type: 'number',
    align: 'right',
    valueGetter: ({ row }) =>
      durationDays(new Date(), new Date(row.event_date_local)),
    cellClassName: ({ row }) => {
      const days_to_event = row.event_date_local
        ? durationDays(new Date(), new Date(row.event_date_local))
        : null;
      return !row.confirmed_at && days_to_event !== null && days_to_event < 7
        ? 'warning'
        : '';
    },
  },
  stringDateTime({
    field: 'invoice_date',
    minWidth: 150,
  }),
  { field: 'is_sale_guarantee', type: 'boolean' },
  {
    field: 'sale_price_per',
    headerName: 'cf_sale_price_per',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'num_seats',
    type: 'number',
    align: 'right',
    valueGetter: ({ row }) => row.seats.length,
  },
  {
    field: 'days_listed',
    type: 'number',
    align: 'right',
    valueGetter: ({ row }) =>
      row.listed && row.invoice_date
        ? durationDays(new Date(row.listed), new Date(row.invoice_date))
        : null,
  },

  stringDateTime({
    field: 'confirmed_at',
  }),
];

const filter_specs: QuickFilterSpec[] = [
  { id: 'is_confirmed', label: 'Confirmed?', yesNoAny: true },
  { id: 'Sale 1-3', label: 'Sale 1-3?', yesNoAny: true },
];

export default function SalesDataGrid({ rows }: { rows: Sale[] }) {
  const {
    baseData: { include_test_data, getClient, getSalesBackFillDate },
  } = useBaseData();

  const filtered = _.filter(
    (r) => r.owner_is_client || include_test_data,
    rows,
  );

  localStorage.setItem(
    'sales-grid-column-pinning',
    JSON.stringify({ left: ['id', 'Sale 1-3', 'days_to_event'] }),
  );
  return PageDataGrid({
    id: 'sales',
    rows: filtered,
    column_specs: [
      ...column_specs,
      {
        field: 'is_confirmed',
        valueGetter: (params) =>
          !!params.row.confirmed_at ||
          new Date(params.row.invoice_date) < getSalesBackFillDate(),
        type: 'boolean',
      },
      {
        field: 'Sale 1-3',
        valueGetter: ({ row }) =>
          !!getClient(row.client_id)?.first_three_sales.some(
            (inv_id) => inv_id === row.id,
          ),
        type: 'boolean',
        cellClassName: ({ value }) => (value ? 'success' : ''),
      },
    ],
    column_groups,
    filter_specs,
    sortModel: [
      { field: 'is_confirmed', sort: 'asc' },
      { field: 'invoice_date', sort: 'asc' },
    ],
  });
}
