import h from '../../../lib/react-hyperscript';

import CachedDataPanel from '../../../common/components/CachedDataPanel';
import { SaleGuaranteeDataGrid } from './SaleGuaranteeDataGrid';
import { useCachedData } from '../../../common/cached_data';

export function SaleGuaranteeListPanel() {
  const { invalidateData } = useCachedData('sale_guarantee');
  return h(CachedDataPanel, {
    header: 'Price Guarantee',
    cache_key: 'sale_guarantee',
    componentFn: ({ rows }) =>
      h(SaleGuaranteeDataGrid, {
        rows,
        onDataUpdated: invalidateData,
      }),
  });
}
